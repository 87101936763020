<template>
  <div class="outer-style">
    <el-card shadow="never">
      <el-form :inline="true" :model="searchForm">
        <el-form-item>
          <el-input
            clearable
            v-model="searchForm.userName"
            placeholder="学员姓名"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            v-model="searchForm.courseName"
            placeholder="课程名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="searchForm.status"
            placeholder="学员状态"
          >
            <el-option
              v-for="item in options"
              :key="item.key"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getListHandler">查询</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
        <el-button style="float: right" type="primary" @click="openSelectModal"
          >学员报名</el-button
        >
      </el-form>
      <el-table :size="size" :border="true" :data="tableData">
        <el-table-column align="center" prop="userName" label="学员姓名" />
        <el-table-column align="center" prop="courseName" label="课程名称">
          <template slot-scope="scope">
            <el-tooltip :content="scope.row.courseName" placement="top">
              <span class="text-oneline">
                {{ scope.row.courseName }}
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          sortable
          prop="createTime"
          label="报名日期"
          width="150"
        />
        <el-table-column align="center" prop="className" label="所在班级">
          <template slot-scope="scope">
            <el-tooltip :content="scope.row.className" placement="top">
              <span class="text-oneline">
                {{ scope.row.className }}
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="amount" label="剩余课时" />
        <el-table-column align="center" prop="usedAmount" label="已课消" />
        <el-table-column align="center" prop="status" label="状态">
          <template slot-scope="scope">
            <span :class="statusOptions[scope.row.status]">
              {{ scope.row.status }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="action" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              style="margin-right: 10px"
              @click="openDetailModal(scope.row)"
              >课消明细</el-button
            >
            <el-popconfirm
              @onConfirm="confirmStatus(scope.row)"
              @confirm="confirmStatus(scope.row)"
              :title="`确定要为该学员退课吗？`"
            >
              <template v-if="scope.row.status == '在读'">
                <el-button type="text" slot="reference"> 退课 </el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
    <SelectStudentModal
      ref="select"
      title="选择"
      @openAddModal="openAddModal"
    />
    <AddStudentModal ref="add" title="添加" @submit="addMember" />
    <EditModal ref="edit" title="编辑" :item="item" @submit="editMember" />
    <StudentCourseDetailModal
      :searchForm="searchForms"
      ref="detail"
      title="学员课程课消明细"
    />
  </div>
</template>
<script>
import { addStudent, editStudent } from "@/api/member/student";

import {
  getCourseMemberList,
  leaveCourse,
} from "@/api/businesscourse/course";
import AddStudentModal from "./components/AddStudentModal";
import EditModal from "@/views/member/EditModal";
import StudentCourseDetailModal from "./components/StudentCourseDetailModal";
import SelectStudentModal from "./components/SelectStudentModal";
export default {
  components: {
    AddStudentModal,
    EditModal,
    StudentCourseDetailModal,
    SelectStudentModal,
  },
  data() {
    return {
      size: "small",
      item: {},
      title: "",
      tableData: [],
      detailTableData: [],
      detailPageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      searchForm: { userName: null, courseName: null, status: null },
      searchForms: {},
      statusOptions: {
        //'未开始','在读','结课','退课'
        未开始: "start-class",
        在读: "read-class",
        结课: "end-class",
        退课: "quit-class",
      },
      options: [
        {
          value: "未开始",
          label: "未开始",
        },
        {
          value: "在读",
          label: "在读",
        },
        {
          value: "结课",
          label: "结课",
        },
        {
          value: "退课",
          label: "退课",
        },
      ],
    };
  },
  created() {
    this.getListHandler();
  },
  methods: {
    /**
     * 获取列表
     */
    async getListHandler() {
      let responseData = await getCourseMemberList({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        ...this.searchForm,
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
    /**
     * 获取详情
     */
    async getDetail(id) {
      return this.tableData.filter((item) => item.id == id)[0];
    },
    confirmStatus(item) {
      const { userId, courseId } = item;
      leaveCourse({
        userId,
        courseId,
      }).then((res) => {
        if (res.state == "success") {
          this.$message.success("退课成功");
          this.getListHandler();
        }
      });
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.searchForm = {
        userName: null,
        courseName: null,
        status: null,
      };
      this.getListHandler();
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * 打开添加弹窗
     */
    openAddModal() {
      this.$refs.add.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.add.initData();
      });
    },
    /**
     * 打开报名
     */
    openSelectModal() {
      this.$refs.select.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.select.initData();
      });
    },
    /**
     * 打开详情
     */
    openDetailModal(item) {
      this.searchForms={
        ...item
      };
      this.$nextTick(() => {
          this.$refs.detail.drawer = true;
      });
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
    /**
     * 添加成员
     */
    async addMember(value) {
      const { name, tel, pwd, loginName, state } = value;
      const response = await addStudent({ name, tel, pwd, loginName, state });
      if (response.state == "success") {
        // this.pageInfo.pageNum = 1;
        // this.pageInfo.pageSize = 10;
        this.$message.success("添加成功");
        this.getListHandler();
        this.$refs.add.dialogVisible = false;
      }
    },
    async editMember(value) {
      const { id, name, tel, pwd, state } = value;
      const response = await editStudent({
        id,
        name,
        tel,
        pwd,
        state,
      });
      if (response.state == "success") {
        this.$message.success("编辑成功");
        //   this.pageInfo.pageNum = 1;
        //   this.pageInfo.pageSize = 10;
        this.getListHandler();
        this.$refs.edit.dialogVisible = false;
      } else {
        this.$message.warning("编辑失败");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}

.start-class,
.end-class,
.read-class,
.quit-class {
  display: inline-block;
  position: relative;
}
.start-class::before,
.end-class::before,
.read-class::before,
.quit-class::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.start-class::before {
  background-color: gray;
}
.read-class::before {
  background-color: blue;
}
.quit-class::before {
  background-color: red;
}
.end-class::before {
  background-color: green;
}
</style>
